import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  Box,
  useColorMode,
} from "@chakra-ui/react";

export default function HeirloomIntro() {
  const { colorMode } = useColorMode();

  return (
    <Box p={4} as="section">
      {" "}
      {/* 의미론적 태그 사용 */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
        <Container maxW={"80%"}>
        <Flex flexWrap="wrap" gridGap={20} justify="center">
          <Image
            mt="2rem"
            rounded={"md"}
            alt={"Heirloom Illustration"}
            src={"./heirloom-img.webp"}
            objectFit={"cover"}
          />
        </Flex>
      </Container>
      <Stack mt={"4rem"} as={Container} maxW={"80%"} textAlign={"center"}>
        <Text mb={"0rem"} fontSize={"xl"} fontWeight={"normal"}>
        "소중한, 남기고 싶은"
        </Text>
        <Heading
          fontSize={{ base: "2rem", sm: "4rem" }}
          fontWeight="bold"
          color={colorMode === "dark" ? "gray.300" : "#746E65"}
          as="h2" // SEO를 위한 의미론적 태그 사용
        >
          유품 찾기
        </Heading>
        <Text mt={"3rem"} fontSize={{ base: "2xl", sm: "4xl" }}>
          보물찾기처럼<br/>
          어플 곳곳에 숨어있는<br/>
          유품을 찾아가며 소중한<br/>
          추억을 회상합니다.<br/>
        </Text>
      </Stack>
      </SimpleGrid>
    </Box>
  );
}
