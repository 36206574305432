import React from "react";

export default class Link extends React.Component {
  async componentDidMount() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
    async function replacePage() {
      const invite = new URLSearchParams(window.location.search).get('invite');
      if (invite) {
        console.log(invite)
        let response = await fetch(\`https://cyqrsvtqexkzngdereuk.supabase.co/functions/v1/link?invite=\${invite}\`)
        data = await response.json()
        const html = data.html
        document.write(html)
      }
    }
    replacePage()
    `;
    document.body.appendChild(script);
  }
  
  render(): React.ReactNode {
    return (<></>);
  }
}