import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";

export default function ScheduledIntro() {
  const { colorMode } = useColorMode();

  return (
    <Box
      pb="5rem"
      id="scheduled-section"
    >
      <Container centerContent textAlign="center" mb={"5rem"} mt={8}>
        <Heading
          mb={{ base: "1rem", sm: "3rem" }}
          fontSize={{ base: "1.875rem", sm: "3.75rem" }}
          fontWeight="bold"
          color={colorMode === "dark" ? "gray.300" : "#500D79"}
          as="h2" // SEO를 위한 의미론적 태그 사용
        >
          출시 예정 서비스
        </Heading>
        <Box
        w={{ base: "6rem", sm: "10rem" }}
        h={{ base: "0.8rem", sm: "1rem" }}
        bgColor={"#D1C4E9"}
        rounded={"xl"}
        >
        </Box>
      </Container>

      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
      <Stack mt={{ base: "2rem", sm: "5rem" }} as={Container} maxW={"80%"} textAlign={"center"}>
        <Heading
          fontSize={{ base: "2rem", sm: "4rem" }}
          fontWeight="bold"
          color={colorMode === "dark" ? "gray.300" : "#005FB7"}
          as="h2" // SEO를 위한 의미론적 태그 사용
        >
          A.I. 종이비행기
        </Heading>
        <Text mt={"3rem"} fontSize={{ base: "2xl", sm: "4xl" }}>
          작성하신 편지와 함께<br/>
          고인의 사진 한 장을<br/>
          종이 비행기로 날리면<br/>
          답을 받아볼 수 있습니다.
        </Text>
      </Stack>
      <Container maxW={"80%"} maxH={"80%"}>
        <Flex flexWrap="wrap" gridGap={20} justify="center">
          <Image
            w={"80%"}
            mt="2rem"
            rounded={"3xl"}
            alt={"AI 종이비행기 이미지"}
            src={"./aiplane-image.webp"}
            objectFit={"cover"}
          />
        </Flex>
      </Container>
      </SimpleGrid>
    </Box>
  );
}