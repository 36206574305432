import {
  Container,
  Stack,
  Heading,
  Text,
  Image,
  Box,
  Flex,
  Link,
  HStack,
} from "@chakra-ui/react";
import WordCarousel from "./WordCarousel";

import IntroButton from "./IntroButton"; // IntroButton 컴포넌트를 import

const buttonData = [
  {
    imageSrc: "./1.webp",
    size: "9.6875rem",
    size_base:"5.6875rem",
    size_sm:"7.6875rem",
    positionStyles: { top: "70%", right: "52%" },
    tooltipText: "버블 1입니다!",
  },
  {
    imageSrc: "./2.webp",
    size: "6.25rem",
    //size_base:"5.6875rem",
    //size_sm:"7.6875rem",
    positionStyles: { top: "30%", right: "24%" },
    tooltipText: "버블 2입니다!",
  },
  {
    imageSrc: "./3.webp",
    size: "3.75rem",
    size_base:"5.6875rem",
    size_sm:"7.6875rem",
    positionStyles: { top: "30%", right: "15%" },
    tooltipText: "버블 3입니다!",
  },
  {
    imageSrc: "./4.webp",
    size: "9.375rem",
    //size_base:"4.6875rem",
    size_sm:"7.6875rem",
    positionStyles: { top: "10%", right: "33%" },
    tooltipText: "버블 4입니다!",
  },
  {
    imageSrc: "./5.webp",
    size: "18.75rem",
    //size_base:"8.6875rem",
    //size_sm:"12.6875rem",
    positionStyles: { top: "40%", right: "27%" },
    tooltipText: "버블 5입니다!",
  },
  {
    imageSrc: "./6.webp",
    size: "7.5rem",
    size_base:"4.6875rem",
    size_sm:"7.6875rem",
    positionStyles: { top: "60%", right: "18%" },
    tooltipText: "버블 6입니다!",
  },
  {
    imageSrc: "./7.webp",
    size: "9.0625rem",
    size_base:"5.6875rem",
    size_sm:"7.6875rem",
    positionStyles: { top: "10%", right: "6%" },
    tooltipText: "버블 7입니다!",
  },
];

export default function CallToActionWithVideo() {
  return (
    <div>
      <Container
        position="relative" // Container에 position 속성 추가
        bgImg={"./mainpagesky.webp"}
        bgSize={"cover"}
        maxW={"100%"}
        minW={"100%"}
        minH={"90vh"}
        maxH={"100vh"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Stack
          position={"absolute"}
          maxW={"60wh"}
          minW={"60wh"}
          minH={"80vh"}
          maxH={"100vh"}
          align={"center"}
          ml={{ base: 4, md: 10 }}
          mr={{ base: 4, md: 10 }}
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
          direction={{ base: "column", md: "row" }}
        >
          <Stack flex={2} spacing={"2"} position={"relative"}>
            <Heading
              lineHeight={1.1}
              fontWeight={"extrabold"}
              fontSize={{
                base: "clamp(1rem, 20vw, 3rem)",
                md: "clamp(1.5rem, 12.5vw, 4rem)",
              }}
              zIndex={1}
              mt={{ base: 5, md: 14 }}
              mb={{ base: 0, md: 2 }}
              textColor={"whiteAlpha.900"}
            >
              {/* 기존의 Text 내용 */}
              <Text
                as={"span"}
                position={"relative"}
                _after={{
                  content: "''",
                  width: "100%",
                  height: "40%",
                  position: "absolute",
                  bottom: -1,
                  left: 0,
                  zIndex: -1,
                }}
                _hover={{
                  textColor: "blackAlpha.700",
                }}
              >
                <Text
                  as={"span"}
                  fontSize={{
                    base: "clamp(1rem, 20vw, 1.5rem)",
                    md: "clamp(1rem, 10vw, 2.3rem)",
                  }}
                  _hover={{
                    textColor: "blackAlpha.700",
                  }}
                >
                  그리운 이를 만나는 특별한 공간
                  <br />
                </Text>
                <Text
                  fontSize={{
                    base: "clamp(1rem, 20vw, 0.8rem)",
                    md: "clamp(1rem, 10vw, 1rem)",
                  }}
                >
                  <br />
                </Text>

                <Text
                  fontSize={{
                    base: "clamp(1rem, 20vw, 0.8rem)",
                    md: "clamp(1rem, 10vw, 1rem)",
                  }}
                >
                  <br />
                </Text>
                <Stack direction={{base:'column',sm:"row",md:"row"}}>
                  <WordCarousel />
                  <Text
                    fontSize={{
                      base: "clamp(1rem, 20vw, 2.2rem)",
                      sm: "clamp(1rem, 20vw, 2.9rem)",
                      md: "clamp(1.5rem, 12.5vw, 5rem)",
                    }}
                  >
                    온라인 추모관
                  </Text>
                </Stack>
              </Text>
            </Heading>
            <Stack
              spacing={"2rem"}
              direction={{ base: "column", sm: "row" }}
              zIndex={2}
            >
              <Box
              as={HStack}
              mt={{ base: "20rem",sm:"15rem", md: "10rem" }}
              position={{ base: "absolute", md: "relative" }} // 모바일에서는 absolute, 데스크톱에서는 relative
              bottom={{ base: "-5rem", md: "auto" }} 
              >
                <Box
                  as={Link}
                  href="https://play.google.com/store/apps/details?id=life.forlegacy.app"
                  isExternal
                  mr={4}
                  width={{base:"45%",sm:"12rem",md:"15rem"}}
                >
                  <Image
                    src={"/google-play-badge.webp"}
                    alt="Download Forlegacy on Google Play"
                    width="100%" // 이미지의 너비를 100%로 설정
                    height="auto" // 이미지의 높이를 자동으로 조정
                  />
                </Box>
                <Box
                  as={Link}
                  href="https://apps.apple.com/kr/app/%ED%8F%AC%EB%A0%88%EA%B1%B0%EC%8B%9C-%EB%8B%B9%EC%8B%A0%EC%9D%98-%EC%82%B6%EC%9D%B4-%EB%B3%84%EC%B2%98%EB%9F%BC-%EB%B9%9B%EB%82%98%EA%B2%8C/id6463806133"
                  isExternal
                  width={{base:"45%",sm:"12rem",md:"15rem"}}
                >
                  <Image
                    src={"/app-store-badge.svg"}
                    alt="Download Forlegacy on the App Store"
                    width="100%" // 이미지의 너비를 100%로 설정
                    height="auto" // 이미지의 높이를 자동으로 조정
                  />
                </Box>
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Box zIndex={-1}>
          {buttonData.map((button, index) => (
            <IntroButton
              key={index}
              imageSrc={button.imageSrc}
              size={button.size}
              size_base={button.size_base}
              size_sm={button.size_sm}
              positionStyles={button.positionStyles}
              tooltipText={button.tooltipText}
            />
          ))}
        </Box>
      </Container>
    </div>
  );
}

