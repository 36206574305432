import {
  Box,
  chakra,
  Container,
  Divider,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";
import { FaAnchor, FaEnvelope, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { ReactNode } from "react";

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <Box w="100%">
      <Container
        as={Stack}
        maxW={"5xl"}
        direction={{ base: "column", md: "row" }}
        spacing={2}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Text fontSize={{base:"sm",md:"md"}}>대표자: 강은혜 </Text>
        <Text fontSize={{base:"sm",md:"md"}}>사업등록번호 : 424-81-03105 </Text>
        <Text fontSize={{base:"sm",md:"md"}}>이메일 : a@forlegacy.life</Text>
        <Text fontSize={{base:"sm",md:"md"}}>주소 : 서울 강남구 역삼로 172, Maru360 5층 포레거시</Text>
      </Container>
      <Stack justify={"center"} align={"center"} mt={"1rem"}>
        <Text>© 2023 Forlegacy. All rights reserved</Text>
      </Stack>
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={"end"}
        align={"center"}
      >
        <Stack direction={"row"} spacing={6}>
          <SocialButton label={"Twitter"} href={"#"}>
            <FaTwitter />
          </SocialButton>
          <SocialButton
            label={"KaKaoTalk"}
            href={"https://open.kakao.com/o/gXR"}
          >
            <FaEnvelope />
          </SocialButton>
          <SocialButton
            label={"YouTube"}
            href={"https://www.youtube.com/@4Legacy_official"}
          >
            <FaYoutube />
          </SocialButton>
          <SocialButton
            label={"Instagram"}
            href={"https://instagram.com/forlegacy_official?igshid=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr"}
          >
            <FaInstagram />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  );
}
