import { Box, Flex, Stack, useColorModeValue } from "@chakra-ui/react";

import Footer from "../components/Footer";

import CallToActionWithVideo from "../components/intro/IntroButtonVideo";
import VideoContents from "../components/nowVideo/NowContents";

import ScheduledIntro from "../components/serviceIntro/ScheduledIntro";
import PromotionIntro from "../components/serviceIntro/PromotionIntro";
import HeirloomIntro from "../components/serviceIntro/heirloomIntro";
import EventIntro from "../components/serviceIntro/EventIntro";
import ThreeTierPricing from "../components/reservation/Reservation";
import { Helmet } from "react-helmet";
import {
  GalleryIntro,
  LifeStoryIntro,
  TimelineIntro,
} from "../components/serviceIntro/contents";
import ServiceIntroduction from "../components/serviceIntro/ServiceIntroduction";
import ServiceIntro from "../components/serviceIntro/ServiceIntro";

export default function Home() {
  return (
    <Stack
      bg={useColorModeValue("#F5F5F5", "gray.800")}
      justifyContent={"center"}
      justifyItems={"center"}
      alignItems={"center"}
      spacing={"0"}
      mx={{base : "10%", sm: "0",md:"0"}}
    >
      <Helmet>
        <title>온라인 추모관 | 포레거시</title>
        <meta
          name="description"
          content="포레거시는 '사랑하는 고인의 삶의 흔적을 보존'하고 '그리움을 해소'할 수 있는 유일한 프라이빗 추모 앱 서비스입니다."
        />
        <meta
          name="keywords"
          content="온라인 추모관, 포레거시, 추모 앱, 사랑하는 고인 추모, 프라이빗 추모 공간"
        />
      </Helmet>

      <Box w="100%" h="100%">
        <CallToActionWithVideo />
      </Box>
      <Box
        position={"relative"}
        top={{ base: "-3.75rem", sm: "-1.25rem", lg: "-3.75rem" }}
        w={{ base: "90%", md: "80%" }}
        h="60%"
        rounded={"2xl"}
        paddingY={{ base: "2rem", md: "10rem" }}
        paddingX={{ base: "0.5rem", md: "0.5rem" }}
      >
        <VideoContents />
      </Box>

      <Box w="100%" h="100%">
        <ServiceIntro/>
      </Box>
      <LifeStoryIntro />
      <Box
        w="100%"
        //bgImg={"./lifestory-bg.webp"}
        //bgSize={"cover"}
        maxW={"100%"}
        minW={"100%"}
      >
        <GalleryIntro />
      </Box>
      <Box
        w="100%"
        //bgImg={"./timeline-bg.webp"}
        //bgSize={"cover"}
        maxW={"100%"}
        minW={"100%"}
      >
        <TimelineIntro />
      </Box>
      <Box w="100%">
        <ServiceIntroduction />
      </Box>
      <Box
        w="100%"
        //bgImg={"./gradation-bg.webp"}
        //bgSize={"cover"}
        maxW={"100%"}
        minW={"100%"}
      >
        <ScheduledIntro />
      </Box>
      <Box
        w="100%"
        //bgImg={"./gradation-bg2.webp"}
        //bgSize={"cover"}
        maxW={"100%"}
        minW={"100%"}
      >
        <HeirloomIntro />
      </Box>
      <Box
        pt={"0.5rem"}
        pb={{ base: "3rem", md: "7rem" }}
        //bg={"#B8CFFF"}
        w="100%"
      >
        <EventIntro />
      </Box>
      {/* <Box w="100%" mb={"2rem"}>
        <ThreeTierPricing />
      </Box> */}
      <Flex mt="13rem" mb="5rem" w="100%" justify={"center"} >
        <PromotionIntro />
      </Flex>
      <Footer />
    </Stack>
  );
}
