import { createBrowserRouter } from "react-router-dom";
import NotFound from "./routes/NotFound";
import Home from "./routes/Home";
import Link from "./routes/Link";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <NotFound />,
  },
  {
    path: "link",
    element: <Link />,
  },
]);

export default router;
