import React, { useState, useEffect } from 'react';
import { Text, Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const words = ['초개인화', '프리미엄', '프라이빗'];

const WordCarousel = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000); // 2초마다 단어가 바뀜

    return () => clearInterval(interval);
  }, []);

  return (
    <Box textAlign="start">
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 20 }}
        transition={{ duration: 1 }}
        key={currentWordIndex}
      >
        <Text
        color={"#00CFFD"}
        fontSize={{
          base: "clamp(1rem, 20vw, 2.7rem)",
          sm: "clamp(1rem, 20vw, 3rem)",
          md: "clamp(1.5rem, 12.5vw, 5rem)",
        }}
        >
          {words[currentWordIndex]}
        </Text>
      </motion.div>
    </Box>
  );
};

export default WordCarousel;
