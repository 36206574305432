import { ThemeConfig, extendTheme } from "@chakra-ui/react";

const fonts = {
  heading: `'SCDream1', sans-serif`,
  body: `'SCDream1', sans-serif`,
};

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  fonts,
});

export default theme;
