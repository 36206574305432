import {
  Box,
  Container,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";

export default function ScheduledIntro() {
  const { colorMode } = useColorMode();

  return (
    <Box
      py={4}
      pb="5rem"
      id="story-section"
    >
      <Container centerContent textAlign="center" mb={8} mt={8}>
        <Heading
          mb="0rem"
          fontSize={{ base: "2rem", sm: "4rem" }}
          fontWeight="bold"
          color={colorMode === "dark" ? "gray.300" : "#500D79"}
          as="h2" // SEO를 위한 의미론적 태그 사용
        >
          서비스 소개
        </Heading>
      </Container>
    </Box>
  );
}
