import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  Image,
  SimpleGrid,
  Link,
  useColorMode,
} from "@chakra-ui/react";

export default function PromotionIntro() {
  const dotRows = [10, 10, 7, 5, 4, 3];
  const { colorMode } = useColorMode();
  return (
    <Flex
      as="section"
      position="relative"
      paddingX="5%"
      maxW={{ base: "80%", md: "90%" }}
      w={"full"}
      h={{ base: "80%", md: "35rem" }}
      borderWidth="0.0rem"
      rounded={'1rem'}
      //bgImg={"./promotion-bg.webp"}
      bgSize={"100% 100%"}
      bgRepeat="no-repeat"
      justifyContent={"center"}
      alignItems={"center"}
      id="download-section"
      zIndex="1"
    >
      <Box
      position="absolute"
      top={0}
      left={0}
      rounded={'2rem'}
      width="100%"
      height="100%"
      background="linear-gradient(135deg, #83a4d4, #b6fbff)"
      overflow="hidden"
      zIndex="-1"
    >
      {/* 오른쪽에 겹쳐진 원들 */}
      {/* <Box
        position="absolute"
        right="-15%"
        bottom="-5%"
        width="400px"
        height="400px"
        borderRadius="50%"
        background="#a0d2eb"  // 색상 조정
        opacity="0.5"
      />
      <Box
        position="absolute"
        right="-10%"
        bottom="0"
        width="300px"
        height="300px"
        borderRadius="50%"
        background="#b6fbff"
        opacity="0.7"
      />
      <Box
        position="absolute"
        right="-5%"
        bottom="5%"
        width="200px"
        height="200px"
        borderRadius="50%"
        background="#83a4d4"  // 색상 조정
      /> */}

      {/* 왼쪽 아래의 점모양 원들 */}
      {/* {dotRows.map((dots, rowIndex) => (
        Array(dots).fill(null).map((_, colIndex) => (
          <Box
            key={`${rowIndex}-${colIndex}`}
            position="absolute"
            left={`${5 + 3 * rowIndex}%`}
            bottom={`${5 + 3 * colIndex}%`}
            width={{ base: "0.35rem", sm: "0.4rem", lg: "0.5rem" }}
            height={{ base: "0.35rem", sm: "0.4rem", lg: "0.5rem" }}
            borderRadius="50%"
            opacity={'50%'}
            background="#ffffff"
          />
        ))
      ))} */}
    </Box>
    
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
        <Stack
          mt={{ base: "2rem", sm: "1rem" }}
          align={"baseline"}
          direction={"column"}
          justifyContent={"center"}
        >
          <Box mt={2}>
            <Heading
              size={{ base: "2xl", sm: "xl", md: "3xl" }}
              color={"black"}
              as="h2"
            >
              지금 바로 만나보세요!
            </Heading>
            <Text
              fontWeight={200}
              mt={{ base: "3rem", sm: "1rem" }}
              fontSize={{ base: "2xl", sm: "xl", md: "4xl" }}
              color={"blackAlpha.600"}
            >
              오직 당신만을 위한 공간입니다.
            </Text>
          </Box>
          <Flex direction={{ base: "row", sm: "row" }} mt={"5rem"} mb={{base:"1rem"}}>
            <Box
              as={Link}
              href="https://play.google.com/store/apps/details?id=life.forlegacy.app"
              isExternal
              mr={4}
              width={{base:"45%",md:"15rem"}}
            >
              <Image
                src={"/google-play-badge.webp"}
                alt="Download on Google Play"
                width="100%" // 이미지의 너비를 100%로 설정
                height="auto" // 이미지의 높이를 자동으로 조정
              />
            </Box>
            <Box
              as={Link}
              href="https://apps.apple.com/kr/app/%ED%8F%AC%EB%A0%88%EA%B1%B0%EC%8B%9C-%EB%8B%B9%EC%8B%A0%EC%9D%98-%EC%82%B6%EC%9D%B4-%EB%B3%84%EC%B2%98%EB%9F%BC-%EB%B9%9B%EB%82%98%EA%B2%8C/id6463806133"
              isExternal
              width={{base:"45%",md:"15rem"}}
            >
              <Image
                src={"/app-store-badge.svg"}
                alt="Download on the App Store"
                width="100%" // 이미지의 너비를 100%로 설정
                height="auto" // 이미지의 높이를 자동으로 조정
              />
            </Box>
          </Flex>
        </Stack>
        <Flex display={{ base: "none", lg: "flex" }}>
          <Image
            position={"relative"}
            zIndex={1}
            top={{ base: "-60rem", sm: "-1.25rem", lg: "-9rem" }}
            left={{ base: "-80rem", sm: "-1.25rem", lg: "5rem" }}
            maxWidth={"95%"}
            maxHeight={"95%"}
            rounded={"md"}
            src={"./forlegacy-img.png"}
            objectFit={"cover"}
            alt="Forlegacy App Image"
          />
        </Flex>
      </SimpleGrid>
    </Flex>
  );
}
