import React from "react";
import {
  Container,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  Box,
  useColorMode,
  SimpleGrid,
} from "@chakra-ui/react";

interface SectionIntroProps {
  title: string;
  subtitle: string;
  description: string;
  imgSrc: string;
  imgAlt: string;
  gradientColors: string;
  imageOnRight: boolean;
  imageAboveText?: boolean;
}

const SectionIntro: React.FC<SectionIntroProps> = ({
  title,
  subtitle,
  description,
  imgSrc,
  imgAlt,
  gradientColors,
  imageOnRight,
  imageAboveText = false,
}) => {
  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";

  return (
    <Box my={"2rem"} p={4} color={isDarkMode ? "gray.500" : "gray.700"}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
      {!imageOnRight && (
        <Container maxW={"80%"}>
        <Flex flexWrap="wrap" gridGap={20} justify="center">
          <Image
            mt="2rem"
            rounded={"md"}
            alt={imgAlt}
            src={imgSrc}
            objectFit={"cover"}
          />
        </Flex>
      </Container>
      )}
      <Stack as={Container} maxW={"80%"} textAlign={"center"}>
        <Text mb={"0rem"} fontSize={{ base: "md", sm: "xl" }} fontWeight={"normal"}>
          {subtitle}
        </Text>
        <Heading
          fontSize={{ base: "3xl", sm: "6xl" }}
          fontWeight={"bold"}
          bgGradient={`linear(to-r, ${gradientColors})`}
          bgClip="text"
        >
          {title}
        </Heading>
        <Text mt={"3rem"} fontSize={{ base: "2xl", sm: "4xl" }}>{description}</Text>
      </Stack>
        {imageOnRight && (
        <Container maxW={"80%"}>
        <Flex flexWrap="wrap" gridGap={20} justify="center">
          <Image
            mt="2rem"
            rounded={"md"}
            alt={imgAlt}
            src={imgSrc}
            objectFit={"cover"}
          />
        </Flex>
      </Container>
      )}
      </SimpleGrid>
    </Box>
  );
};

export const LifeStoryIntro = () => (
  <SectionIntro
    title="생애 일대기"
    subtitle='"찬란한 역사적 순간들"'
    description={`삶의 출발에서부터 \n도착점까지의\n특별했던 순간들을\n감동의 이야기로 남겨봅니다.`}
    imgSrc="./lifestory-img.webp"
    imgAlt="Life Story Illustration"
    gradientColors="#FFDAB9, #FFB6C1, #8A2BE2"
    imageOnRight={false}
  />
);

export const GalleryIntro = () => (
  <SectionIntro
    title="갤러리 아카이브"
    subtitle='"아름다운 순간"'
    description={`아름다운 모습들을\n정성스럽게 담아\n소중한 순간들을\n평생 간직합니다.`}
    imgSrc="./gallery-img.webp"
    imgAlt="Gallery Archive Illustration"
    gradientColors="#704214, #FFC085, #FFD700"
    imageOnRight={true}
  />
);
export const TimelineIntro = () => (
  <SectionIntro
    title="타임라인"
    subtitle='"사건사고"'
    description={`삶에서 중요한 사건들을 나열하여 과거, 현재, 미래의 여정을 시각적으로 담습니다.`}
    imgSrc="./timeline-img.webp"
    imgAlt="Timeline Illustration"
    gradientColors="#2d3436, #636e72, #b2bec3"
    imageOnRight={false}
  />
);
