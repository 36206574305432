import {
  Tooltip,
  Box,
  Text,
  Button,
  Link,
  Flex,
  Image,
} from "@chakra-ui/react";
import { useState } from "react";

export interface bubbleProps {
  imageSrc: string;
  size_base? :string;
  size_sm?:string;
  size?: string;
  positionStyles?: {
    top?: string;
    right?: string;
    bottom?: string;
    left?: string;
  };
  tooltipText?: string;
}

export default function IntroButton({
  imageSrc,
  size = "100px",
  size_base = "0px",
  size_sm = "0px",
  positionStyles = {},
  tooltipText = "Hello!",
}: bubbleProps) {
  const [showTooltip, setShowTooltip] = useState(false); // 툴팁 상태를 관리하는 state를 추가합니다.

  return (
    <Tooltip
      label={
        <Flex
          direction="column"
          w="230px"
          bg="#181514"
          p="4"
          borderRadius="2xl"
        >
          <Text fontSize="sm" opacity="0.35">
            @elliepritts.lens
          </Text>
          <Text fontSize="xl" mt="1" color="white">
            ellie
          </Text>
          <Text fontSize="sm" pt="2" color="white">
            transcendental transmedia magic 🪄🐸 🏳️&zwj;🌈🇲🇽🇮🇪
          </Text>
          <Link href="https://lensfrens.xyz/elliepritts.lens" isExternal pt="6">
            <Button
              w="full"
              bg="dustyMiller"
              color="blackWood"
              mt="4"
              fontSize="sm"
              fontWeight="bold"
              textTransform="uppercase"
            >
              Follow
            </Button>
          </Link>
        </Flex>
      }
      fontSize="md"
      bg="#181514"
      color="white"
      borderRadius="lg"
      hasArrow
      placement="top"
      closeDelay={500} // 툴팁이 닫히기 전까지의 딜레이 시간 (밀리초 단위)
      openDelay={100} // 툴팁이 열리기 전까지의 딜레이 시간 (밀리초 단위)
      closeOnClick={false}
      isOpen={showTooltip} // 툴팁의 열림 상태를 제어합니다.
    >
      <Box
        position="absolute"
        {...positionStyles}
        width={{base:size_base, sm:size_sm, md : size}}
        height={{base:size_base, sm:size_sm, md : size}}
        overflow="hidden"
        _hover={{
          transform: "scale(1.1)",
        }}
        transition="transform 0.2s"
      >
        <Image w={"100%"} h={"100%"} src={imageSrc} objectFit={"cover"} />
      </Box>
    </Tooltip>
  );
}
