import { Button, Heading, Text, VStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <VStack
      backgroundImage={
        "https://cdn.midjourney.com/1771750f-0673-42d7-aa3d-7d090b3826ba/0_0.png"
      }
      backgroundSize={"cover"}
      justifyContent={"center"}
      minH={"100vh"}
    >
      <Heading>Page not Found</Heading>
      <Text>It seems that you're lost.</Text>
      <Link to="/">
        {" "}
        <Button colorScheme={"blackAlpha"} variant={"link"}>
          Go home &rarr;
        </Button>
      </Link>
    </VStack>
  );
}
