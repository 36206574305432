import {
  Box,
  Heading,
  Stack,
  Text,
  chakra,
  useColorMode,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

const ShiningStar = () => (
  <chakra.span
    className="shining-star"
    animation="shine 1.5s infinite alternate"
    textShadow="0 0 0.1875rem #fff, 0 0 0.375rem #aaddff, 0 0 0.5625rem #aaddff, 0 0 0.75rem #aaddff"
  >
    별
  </chakra.span>
);

export default function VideoContents() {
  const { colorMode } = useColorMode();
  const isDarkMode = colorMode === "dark";

  const [blogTitle, setBlogTitle] = useState("");
  const [count, setCount] = useState(0);
  const completionWord = "삶이 별처럼 빛나게";

  useEffect(() => {
    const typingInterval = setInterval(() => {
      if (count >= completionWord.length) {
        clearInterval(typingInterval);

        setTimeout(() => {
          setCount(0);
          setBlogTitle("");
        }, 1000);
        return;
      }

      setBlogTitle((prevTitleValue) => {
        let result = prevTitleValue
          ? prevTitleValue + completionWord[count]
          : completionWord[0];
        setCount(count + 1);

        return result;
      });
    }, 300);

    return () => {
      clearInterval(typingInterval);
    };
  }, [count]);

  const renderBlogTitle = () => {
    return blogTitle
      .split("별")
      .flatMap((part, index) =>
        index === 0 ? [part] : [<ShiningStar key={`${index}-star`} />, part]
      );
  };

  return (
    <Stack direction={{ base: "column", md: "row" }}>
      <Box
        rounded={"3xl"}
        flex={1}
        h={"100%"}
        paddingY={{ base: "5rem", md: "5rem" }}
        textAlign="center"
      >
        <Heading
          fontFamily={"mono"}
          fontSize={{ base: "4xl", md: "6xl" }}
          mb={{ base: "1rem", sm: "0.5rem" }}
          color={isDarkMode ? "white" : "#444444"}
        >
          "당신의 {renderBlogTitle()}"
        </Heading>
        <Text
          mt={"5rem"}
          color={isDarkMode ? "gray.300" : "black"}
          fontSize={{ base: "2xl", md: "3xl" }}
        >
          소중한 이와의 추억, 포레거시에서 담아드리겠습니다.
        </Text>
      </Box>
    </Stack>
  );
}
