import {
  Container,
  Heading,
  Text,
  Stack,
  Box,
  useColorMode,
} from "@chakra-ui/react";

export default function EventIntro() {
  const { colorMode } = useColorMode();

  // 컬러 모드에 따른 텍스트 및 배경 색상 설정
  const textColor = colorMode === "dark" ? "#F6AD55" : "#DD6B20";
  const bgColor = colorMode === "dark" ? "#1A202C" : "white";
  const flashingGradient = `
  background: linear-gradient(45deg, #FF0000, #FF4500, #FFA500, #FFFF00, #FFA500, #FF4500);
  background-size: 200% 200%;
  animation: flashing 3s linear infinite;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
`;
  return (
    <Box p={4} 
    //bg={bgColor}
    id="event-section"
    >
      <Stack my={{ base: "4rem", md: "12rem" }} spacing={4} as={Container} maxW={"80%"} textAlign={"center"}>
        <Heading
          my={{ base: "1rem", md: "3rem" }}
          fontSize={{ base: "3xl", md: "7xl" }}
          fontWeight={"bold"}
          css={flashingGradient} // 번쩍번쩍한 그라데이션
        >
          OPEN EVENT
        </Heading>
        <Text
          fontWeight={"bold"}
          color={textColor} // 이벤트 문구 컬러
          fontSize={{ base: "2xl", md: "6xl" }}
        >
          현재 오픈이벤트 진행중입니다!
        </Text>
      </Stack>
    </Box>
  );
}
