import {
  Box,
  Container,
  Heading,
  Stack,
  Image,
  Flex,
  Text,
  VStack,
  Icon,
  ListItem,
  UnorderedList,
  useColorMode,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import React from "react";

interface SimpleIntroProps {
  title: string;
  subtitle: string;
}

interface ForlegacyIntroProps {
  title: string;
  subtitle: string;
  description: string;
  imgSrc: string;
  imgAlt: string;
  features: string[];
}

const SimpleIntro: React.FC<SimpleIntroProps> = ({ title, subtitle }) => {
  return (
    <Box p={4}>
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <Heading
          mt="1.5rem"
          fontSize={{ base: "3xl", sm: "6xl" }}
          fontWeight={"bold"}
        >
          {title}
          <br />
          {subtitle}
        </Heading>
      </Stack>
    </Box>
  );
};

const ForlegacyIntro: React.FC<ForlegacyIntroProps> = ({
  title,
  subtitle,
  description,
  imgSrc,
  imgAlt,
  features,
}) => {
  const { colorMode } = useColorMode(); // Chakra UI의 useColorMode 훅 사용

  // colorMode에 따른 색상 선택
  const bgColor = colorMode === "light" ? "#FFDAB9" : "#2D3748";
  const textColor = colorMode === "light" ? "#8B4513" : "#E2E8F0";

  return (
    <Container maxW={"7xl"} py={12} mb={"2rem"}>
      <Flex
        direction={{ base: "column", md: "row" }}
        alignItems="flex-start"
        alignContent={"flex-start"}
      >
        <Box
          display={{ base: "none", lg: "flex" }}
          flex={4}
          pr={{ md: "2rem" }}
          h={"30rem"}
        >
          <Image
            rounded={"md"}
            src={imgSrc}
            objectFit={"cover"}
            w="100%"
            h="100%"
            alt={imgAlt}
          />
        </Box>
        <Box flex={6} width="100%" h={"30rem"}>
          <VStack
            spacing={2}
            align="start"
            h="100%"
            justifyContent="space-between"
          >
            <Box
              bgColor={bgColor}
              p={5}
              rounded={"xl"}
              shadow={"2xl"}
              width="100%"
              textAlign="center"
              position="relative"
            >
              <Heading fontSize="4xl" color={textColor}>
                {title}
              </Heading>
              <Text fontSize="lg" fontWeight="medium" mt={4} color={textColor}>
                {subtitle}
              </Text>
              <Box
                position="absolute"
                top={-2}
                right={-2}
                bgGradient="linear(to-bl, #FFDAB9, #FFB6C1)"
                w={10}
                h={10}
                transform="rotate(45deg)"
              ></Box>
            </Box>
            <Flex
              p={6}
              borderWidth={1}
              rounded={"md"}
              shadow={"lg"}
              width="100%"
              flexDirection="column"
              flexGrow={1}
            >
              <Text fontSize={"2xl"} fontWeight={"bold"} textAlign="center">
                {description}
              </Text>
              <UnorderedList
                spacing={4}
                ml={5}
                mt={5}
                textAlign="left"
                styleType="none"
                flexGrow={1}
                fontSize={{ base: "xl", sm: "2xl" }}
              >
                {features.map((feature, index) => (
                  <ListItem key={index}>
                    <Flex alignItems="center">
                      <Icon as={CheckIcon} color="green.500" mr={2} />
                      {feature}
                    </Flex>
                  </ListItem>
                ))}
              </UnorderedList>
            </Flex>
          </VStack>
        </Box>
      </Flex>
    </Container>
  );
};

const ServiceIntroduction: React.FC = () => {
  return (
    <Container maxW={"100%"} py={12}>
      <SimpleIntro
        title="포레거시는 단순한"
        subtitle="추모관 서비스가 아닙니다."
      />
      <ForlegacyIntro
        title="포레거시는"
        subtitle="'소중한 이'와 더불어 살아가고 있음을 느낄 수 있게 합니다."
        description="우리가 제공하는 특별함"
        imgSrc="./Bitmap.webp"
        imgAlt="Forlegacy Intro Illustration"
        features={[
          "계절과 날씨 등의 상황에 알맞은 앱 테마 제공",
          "작가님이 직접 일대일로 인터뷰 진행",
          "AI휴먼 컨텐츠를 통해 꾸준한 만남 제공",
          "블록체인을 통해 디지털 자산 보호",
        ]}
      />
    </Container>
  );
};

export default ServiceIntroduction;
